import { CircularProgress } from "@mui/material";
import AccountBillingForm from "forms/AccountBillingForm";
import AccountSubscriptionForm from "forms/AccountSubscriptionForm";
import { plans } from "helpers/pricing-plans";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "services";
import { fetchUserData } from "services/auth";
import ChooseSubscription from "./ChooseSubscription";


const AccountSubscription = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const status = useSelector((state) => state.auth.status);
  const token = Cookies.get("propToken");
  const selectedPlan = plans.find(
    (plan) => plan.id === userData.subscriptionId
  );

  useEffect(() => {
    if (Object.keys(userData).length !== 0) return;
    console.log("UE:[userData]")
    dispatch(fetchUserData(token));
  }, [userData]);

  const selectCurrentPlan = () => {
    navigate(`plan/${userData.subscriptionId}`);
  };

  const selectUpgradePlan = () => {
    navigate(`upgrade/${userData.subscriptionId}`);
  };

  const selectCancelPlan = () => {
    navigate(`cancel/${userData.subscriptionId}`);
  };

  const selectAccountBillingDetails = () => {
    navigate("details");
  };

  return (
    <>
      {status !== "success" ? (
        <div className='flex items-center justify-center'>
          <CircularProgress />
        </div>
      ) : userData.subscriptionId ? (
        <>
          <div className='pt-12 pl-7 w-[60%]'>
            <h2 className='text-3xl font-bold mb-6'>Manage subscription</h2>
            <>
            {
              userData.subscriptionExpired ? (
                <h2 className='text-xl font-bold mb-6  text-red'>Your free trial has expired - please upgrade to a paid plan</h2>
              ) : (
                  <>
                  {
                      userData.expiryDate &&  userData.subscriptionId == 1? (
                        <h2 className='text-xl font-bold mb-6 text-red'>Your free trial expires in {userData.remainingTrialDays} days</h2>
                      ) : (<div></div>)
                  }
                  </>
              )
            }
            </>
            <AccountSubscriptionForm
              selectedPlan={selectedPlan}
              selectCurrentPlan={selectCurrentPlan}
              selectUpgradePlan={selectUpgradePlan}
              selectCancelPlan={selectCancelPlan}

            />
          </div>
        </>
      ) : (
        <ChooseSubscription plans={plans} />
      )}
    </>
  );
};

export default AccountSubscription;
