import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import buildingBlocks from "../styles/img/building-blocks.png";
import propLogo from "../styles/img/prop_logo.svg";

const NotFound = () => {
  const navigate = useNavigate();

  const containerStyle = {
    backgroundColor: "rgba(20, 24, 49, 1)",
    height: "100vh",
    overflow: "hidden",
    position: "relative",
  };

  return (
    <Box sx={containerStyle}>
      <img
        onClick={() => navigate("/dashboard")}
        src={propLogo}
        alt="logo"
        className="absolute top-9 left-9 w-[30%] cursor-pointer z-20"
      />
      <img
        src={buildingBlocks}
        alt="back"
        className="h-[100vh] absolute right-[-333px]"
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          color: "white",
          zIndex: 20,
        }}
      >
        <Typography variant="h1" sx={{ fontSize: "6rem", fontWeight: "bold" }}>
          404
        </Typography>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Page Not Found
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard")}
          sx={{
            backgroundColor: "#3B82F6",
            "&:hover": { backgroundColor: "#2563EB" },
          }}
        >
          Return to Dashboard
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;