import {useSelector} from "../services";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {MenuItem, Table, TableBody, TableHead, TableRow,} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import ProjectLayoutHeader from "layout/ProjectLayoutHeader";
import Topbar from "layout/Topbar";
import GrayCard from "common/GrayCard";
import Button from "ui/Button";
import {CHLabel, CILabel, CTextField, TCell, CSelect} from "common/EditableTableStyle";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import {beds, leaseTypes, parkingOption, propertyTypes,} from "helpers/appraisal-accommodation-dropdowns";
import {useLocation} from "react-router-dom";
import DebugJson from "common/DebugJson";


import {
    savePropertyTemplates,
    addTemplate,
    deleteTemplate,
    updateTemplate,
    fetchPropertyTemplates
} from "../services/property";


const PropertyTemplateForm = ({handleDone, showPredictedValues}) => {

    const {templates, status} = useSelector(
        (state) => state.property
    );

    const { values } = useSelector(
        (state) => state.predictedValues
    );

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    console.log("PPPP: " + searchParams.debug )

    const dispatch = useDispatch();
    const token = Cookies.get("propToken");
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const defaultPropertyTemplateFormValues = {
        templateName: "",
        propertyType: "",
        bedrooms: "",
        tenure: "",
        parkingFacility: "",
        area: ""
    };

    const handleAddTemplate = () => {
        dispatch(addTemplate())
    };

    const handleDeleteTemplate = (evt, index) => {
        dispatch(deleteTemplate({index}))
        setDisableSaveButton(false)

    };

    const handleTemplateChange = (evt, index) => {
        const {name, value} = evt.target;
        dispatch(updateTemplate({index, name, value}))
        setDisableSaveButton(false)
    }

    const handleSaveTemplates  = (evt) => {
        evt.preventDefault();
        dispatch(savePropertyTemplates( { body:templates, token }))
        setDisableSaveButton(true)
    }

   const getPredictedValue = (templateId) => {

        if (values === undefined || values.length == 0) {
            
            return 0
        }
        let v = values.find(e => e.templateId == templateId)?.value
       return v
   }


    return (
        <>
             <GrayCard>
                       <TableContainer>
                           <Table sx={{minWidth: 700, border: "none", borderCollapse: "separate"}} aria-label='customized table'>
                               <TableHead>
                                   <TableRow key="templateHeaderKey">
                                       <TCell><CHLabel>Template name</CHLabel></TCell>
                                       <TCell><CHLabel>Property type</CHLabel></TCell>
                                       <TCell><CHLabel>Bedrooms</CHLabel></TCell>
                                       <TCell><CHLabel>Tenure</CHLabel></TCell>
                                       <TCell><CHLabel>Parking facility</CHLabel></TCell>
                                       <TCell><CHLabel>Area (ft²)</CHLabel></TCell>
                                       { showPredictedValues ? (<TCell><CHLabel>Predicted Value</CHLabel></TCell>) : '' }
                                       <TCell><IconButton edge="end" onClick={ handleAddTemplate}> <AddCircleOutlineTwoToneIcon/></IconButton></TCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                               {
                                   templates?.map(
                                       (row, index) => (
                                           <TableRow key={index}>
                                               <TCell><CTextField fullWidth name="templateName" value={row.templateName} onChange={(e) => {handleTemplateChange(e, index)}}/></TCell>
                                               <TCell>
                                                   <CSelect fullWidth value={row.propertyType} name='propertyType' onChange={(e) => {handleTemplateChange(e, index)}}>
                                                     { propertyTypes?.map((option, index) => (
                                                         <MenuItem key={index} value={option}>{option} </MenuItem>
                                                     ))}
                                                   </CSelect>
                                               </TCell>
                                               <TCell>
                                                   <CSelect fullWidth value={row.bedrooms} name='bedrooms' onChange={(e) => {handleTemplateChange(e, index)}}>
                                                     { beds?.map((option, index) => (
                                                         <MenuItem key={index} value={option}>{option} </MenuItem>
                                                     ))}
                                                   </CSelect>
                                               </TCell>
                                               <TCell>
                                                   <CSelect fullWidth value={row.tenure} name='tenure' onChange={(e) => {handleTemplateChange(e, index)}}>
                                                     { leaseTypes?.map((option, index) => (
                                                         <MenuItem key={index} value={option}>{option} </MenuItem>
                                                     ))}
                                                   </CSelect>
                                               </TCell>
                                               <TCell>
                                                   <CSelect fullWidth value={row.parkingFacility} name='parkingFacility' onChange={(e) => {handleTemplateChange(e, index)}}>
                                                     { parkingOption?.map((option, index) => (
                                                         <MenuItem key={index} value={option}>{option} </MenuItem>
                                                     ))}
                                                   </CSelect>
                                               </TCell>
                                               <TCell><CTextField fullWidth name="area" value={row.area} onChange={(e) => {handleTemplateChange(e, index)}}/></TCell>
                                               { showPredictedValues ? (<TCell><CTextField fullWidth disabled name="predicted" value={getPredictedValue(row.id)} /></TCell>) : '' }
                                               <TCell><IconButton edge="end" onClick={(e) => {handleDeleteTemplate(e, index)}}> <DeleteOutlineTwoToneIcon/></IconButton></TCell>
                                           </TableRow>
                                       )
                                   )
                               }
                               </TableBody>
                           </Table>
                       </TableContainer>

                  <div className='flex gap-2 flex-1 mt-5'>
                      <Button
                          type='button'
                          className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end'
                          title='Save'
                          onClick={handleSaveTemplates}
                          disabled={disableSaveButton}
                      />
                      { handleDone ? (
                      <Button
                          type='button'
                          className='bg-green text-background-blue rounded-2xl text-sm pt-3.5 pb-3.5 pr-4 pl-4 self-end'
                          title='Back'
                          onClick={handleDone}
                      />
                      ) : (<div/>)
                      }
                  </div>
            </GrayCard>

            <DebugJson data={[
                ['templates', templates]
            ]}/>

            </>
    );
};

export default PropertyTemplateForm;
