import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import api from "api/api";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "services";
import {
  selectProjectId,
  selectStopPolling,
  setPollingLoading,
  setPollingStatuses,
  setStopPolling,
  setSuccessPolling,
} from "services/project";

import Account from "../Pages/Account/Account";
import AccountDetails from "../Pages/Account/AccountDetails";
import AccountHelp from "../Pages/Account/AccountHelp";
import AccountPrivacy from "../Pages/Account/AccountPrivacy";
import AccountSubscription from "../Pages/Account/AccountSubscription";
import CancelSubscription from "../Pages/Account/CancelSubscription";
import CreateSubscription from "../Pages/Account/CreateSubscription";
import SubscribedPlanDetails from "../Pages/Account/SubscribedPlanDetails";
import UpdatePaymentDetails from "../Pages/Account/UpdatePaymentDetails";
import UpgradeSubscription from "../Pages/Account/UpgradeSubscription";
import PricingPlans from "../Pages/Dashboard/PricingPlans";
import PricingPlansBilling from "../Pages/Dashboard/PricingPlansBilling";
import Projects from "../Pages/Dashboard/Projects";
import FirstPage from "../Pages/FirstPage";
import AccountLayout from "../Pages/Layout/AccountLayout";
import LoginLayout from "../Pages/Layout/LoginLayout";
import RootLayout from "../Pages/Layout/RootLayout";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import Login from "../Pages/Login/Login";
import Login2FA from "../Pages/Login/Login2FA";
import ResetPassword from "../Pages/Login/ResetPassword";
// import Signup from "../Pages/Login/Signup";
// import SignupVerify from "../Pages/Login/SignupVerify";
import AddProject from "../Pages/Project/AddProject";
import FetchProjectData from "../Pages/Project/FetchProjectData";
import ProjectCrime from "../Pages/Project/ProjectCrime";
import ProjectDemographic from "../Pages/Project/ProjectDemographic";
import ProjectEpc from "../Pages/Project/ProjectEpc";
import ProjectMap from "../Pages/Project/ProjectMap";
import ProjectOverview from "../Pages/Project/ProjectOverview";
import ProjectPlanning from "../Pages/Project/ProjectPlanning";
import ProjectReport from "../Pages/Project/ProjectReport";
import ProjectSalesStudy from "../Pages/Project/ProjectSalesStudy";
import ProjectFeasibility from "../Pages/Project/ProjectFeasibility";
import PropertyTemplates from "../Pages/Dashboard/PropertyTemplates";
import Protected from "../Pages/Protected";
import Subscribed from "../Pages/Subscribed";
import Admin from "../Pages/Dashboard/Admin";
import Sales from "../Pages/Sales";
import Activate from "../Pages/Dashboard/Activate";
import NotFound from "Pages/NotFound";

function App() {
  const loginRoutes = [
    { index: true, element: <FirstPage /> },
    { path: "login", element: <Login /> },
    { path: "login/two-factor-auth", element: <Login2FA /> },
    { path: "login/forgot-password", element: <ForgotPassword /> },
    {
      path: "login/forgot-password/reset",
      element: <ResetPassword />,
    },
    // { path: "signup", element: <Signup /> },
    // { path: "signup/verify", element: <SignupVerify /> },
    {
      path: "customer/subscriptions/billing",
      element: (
        <Protected>
          <PricingPlansBilling />
        </Protected>
      ),
    },
    {
      path: "customer/subscriptions",
      element: (
        <Protected>
          <PricingPlans />
        </Protected>
      ),
    },
    {
    path: "customer/subscriptions/activate",
      element: (
        <Protected>
          <Activate />
        </Protected>
      ),
    },
  ];


  const dashboardRoutes = [
    { index: true, element:  <Navigate replace to="/dashboard/projects" />},
    {
      path: "propertytemplates",
      element: <PropertyTemplates />,
    },
    {
      path: "admin",
      element: <Admin />,
    },
    {
      path: "sales",
      element: <Sales />,
    },
    {
      path: "projects/add",
      element: <AddProject />,
    },
    {
      path: "account",
      element: <AccountLayout />,
      children: [
        {
          index: true,
          element: <Account />,
        },
        { path: "subscription", element: <AccountSubscription /> },
        { path: "subscription/plan/:id", element: <SubscribedPlanDetails /> },
        { path: "subscription/upgrade/:id", element: <UpgradeSubscription /> },
        { path: "subscription/cancel/:id", element: <CancelSubscription /> },
        { path: "subscription/details", element: <AccountDetails /> },
        { path: "subscription/create/:id", element: <CreateSubscription /> },
        {
          path: "subscription/details/update-payment",
          element: <UpdatePaymentDetails />,
        },
        {
          path: "subscription/details/update-billing",
          element: <UpdatePaymentDetails />,
        },
        { path: "privacy", element: <AccountPrivacy /> },
        { path: "help", element: <AccountHelp /> },
      ],
    },
    {
      path: "projects",
      element: <Subscribed />,
      children: [
        {
          index: true,
          element: <Projects />,
        },
        {
          path: "details/:id",
          element: <FetchProjectData />,
          children: [
            { index: true, element: <ProjectOverview /> },
            { path: "map", element: <ProjectMap /> },
            { path: "sales-study", element: <ProjectSalesStudy /> },
            { path: "feasibility", element: <ProjectFeasibility />},
            { path: "demographic", element: <ProjectDemographic /> },
            { path: "crime", element: <ProjectCrime /> },
            { path: "epc-register", element: <ProjectEpc /> },
            { path: "planning", element: <ProjectPlanning /> },
            { path: "report", element: <ProjectReport /> },
          ],
        },
      ],
    },
  ];
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginLayout />,
      children: loginRoutes,
    },
    {
      path: "dashboard",
      element: (
        <Protected>
          <RootLayout />
        </Protected>
      ),
      children: [...dashboardRoutes],
    },
    {
      path: "*", // Catch-all route for 404
      element: <NotFound />,
    },
  ]);
  const dispatch = useDispatch();
  const token = Cookies.get("propToken");
  const stopPolling = useSelector(selectStopPolling);
  const projectId = useSelector(selectProjectId);

  const getStatuses = useCallback(async () => {
    dispatch(setPollingLoading(true));
    const check: any = await api.get(
      `/report-generation/projects/${projectId}/status`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (check && check.response && check.response.status >= 400) {
      dispatch(setStopPolling(true));
    } else {
      delete check.data.projectId;

      if (check.data.completedProcess) {
        dispatch(
          setSuccessPolling({ option: false, status: true, stop: true })
        );
        toast.success("Pulling external data completed!");
      } else {
        dispatch(setStopPolling(false));
      }
      dispatch(setPollingStatuses(check.data));
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId !== null && !stopPolling) {
      getStatuses();
      let id = setInterval(getStatuses, 2000);
      return () => clearInterval(id);
    }
  }, [projectId, stopPolling]);

  return <RouterProvider router={router} />;
}

export default App;
